.MuiButton-root {
  text-transform: capitalize !important;
}

a {
  color: #0d6efd;
}

.job-modal .modal-content {
  min-height: 315px !important;
}

legend {
  float: unset;
}

canvas {
  width: 90% !important;
  margin-left: 10%;
}

.legendLabelled {
  display: 'block' !important;
  width: 'auto' !important;
}

/* .MuiCard-root {
  overflow: hidden !important;
  box-shadow: 0px 2px 8px rgb(102 126 234), 0px 2px 1px rgb(102 126 234), 0px 1px 1px rgb(102 126 234) !important;
  border-radius: 8px !important;
}

.MuiOutlinedInput-root {
  position: relative !important;
  border-radius: 16px !important;
  box-shadow: 0px 1px 3px #667eeaa8 !important;
}

.MuiButton-containedPrimary {
  color: #fff !important;
  background-color: #667eea !important;
  border-radius: 16px !important;
} */

.tooltipmsgsSide {
  position: relative;
  display: inline-block;
}

.tooltipmsgsSide .tooltiptextmsgsSide {
  visibility: hidden;
  width: 120px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  overflow-wrap: break-word;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  border: 1px solid;
  /* margin-top: -111px;*/
  /* margin-left: -413px; */
}

.tooltipmsgsSide:hover .tooltiptextmsgsSide {
  visibility: visible;
  word-break: break-all;
  border: 1px solid;
  width: 200px;

  /* inline-size: 300px;
    overflow-wrap: break-word;
    border: 1px solid;
    margin-top: -111px;
    margin-left: -413px; */
}

@media screen and (min-width: 767px) and (max-width: 960px) {
  .tooltipmsgsSide:hover .tooltiptextmsgsSide {
    visibility: visible;
    word-break: break-all;
    border: 1px solid;
    width: 175px;

    /* inline-size: 300px;
      overflow-wrap: break-word;
      border: 1px solid;
      margin-top: -111px;
      margin-left: -413px; */
  }
}

.emailAndMobile {
  display: flex;
}

.left-lable {
  min-width: 60px;
}

.emailAndMobile {
  display: flex;
}

.div-for-lable {
  min-width: 300px;
  margin-left: 15px !important;
}


@media screen and (max-width: 545px) {
  .left-lable {
    min-width: 60px;
  }

  .div-for-lable {
    min-width: 100px;
    margin-left: 0px !important;

  }

  .emailAndMobile {
    display: flex;
    flex-direction: column;
    min-width: 245px;
  }
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(235, 234, 234);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}